// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Dimen } from '../constants';
import {
  Layout,
  LinkedButton,
  MarkdownPs,
  Section,
  SectionFeatures,
  SectionPortals,
  SectionTestimonial,
  SectionScreenshot,
  TabMenu,
  TextHeading,
} from '../components';
import { unwrapConnection } from '../util';

import type { PageFeaturesHostQuery } from '../__generated__/PageFeaturesHostQuery';

type Props = $ReadOnly<{|
  data: PageFeaturesHostQuery,
|}>;

const PageHost = ({
  data: {
    page,
    testimonial,
    allDatoCmsSectionScreenshot,
    allDatoCmsFeatureSection,
  },
}: Props) => {
  if (!page) throw new Error('PageFeaturesHost missing from CMS');
  if (!allDatoCmsFeatureSection)
    throw new Error('Missing allDatoCmsFeatureSection');

  if (!allDatoCmsSectionScreenshot)
    throw new Error('Missing allDatoCmsSectionScreenshot');
  const unwrappedScreenshotSections = unwrapConnection(
    allDatoCmsSectionScreenshot
  );
  const screenshotSections = {};
  [1, 2, 3, 4].forEach(n => {
    const key = `hostFeatures_${n}`;
    screenshotSections[key] = unwrappedScreenshotSections.find(
      b => b.identifier === key
    );
  });

  const unwrappedFeaturesSection = unwrapConnection(allDatoCmsFeatureSection);
  const feauturesSections = {};
  [1, 2, 3, 4].forEach(n => {
    const key = `host_${n}`;
    feauturesSections[key] = unwrappedFeaturesSection.find(
      b => b.identifier === key
    );
  });

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <TabMenu
        links={[
          { url: '/host', label: 'View Host Features', highlight: true },
          {
            url: '/guest',
            label: 'View Guest Features',
            highlight: false,
          },
        ]}
      />
      <Section>
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            {page.heading}
          </TextHeading>
          {page.leadingText && <MarkdownPs html={page.leadingText} />}
          <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
        </StyledLeadingText>
      </Section>
      {testimonial && (
        <SectionTestimonial
          testimonial={testimonial}
          backgroundColor="darkBlue"
        />
      )}
      {screenshotSections.hostFeatures_1 && (
        <SectionScreenshot
          screenshot={screenshotSections.hostFeatures_1}
          imageSrc="/img/screenshots/host_1.png"
          effectSrc="/img/screenshots/effect_1.svg"
          picAfter
          backgroundColor="grey"
        />
      )}
      {feauturesSections.host_1 && (
        <SectionFeatures featuresSection={feauturesSections.host_1} />
      )}
      <Section>
        <div style={{ textAlign: 'center' }}>
          <TextHeading level={4} tag="h2">
            {page.headlinePortals}
          </TextHeading>
        </div>
      </Section>
      <SectionPortals portalsPage={page} />
      {screenshotSections.hostFeatures_2 && (
        <SectionScreenshot
          screenshot={screenshotSections.hostFeatures_2}
          imageSrc="/img/screenshots/host_2.png"
          effectSrc="/img/screenshots/effect_1.svg"
          backgroundColor="grey"
        />
      )}
      {feauturesSections.host_2 && (
        <SectionFeatures featuresSection={feauturesSections.host_2} />
      )}
      {screenshotSections.hostFeatures_3 && (
        <SectionScreenshot
          fullImageSrc="/img/screenshots/host_3.png"
          screenshot={screenshotSections.hostFeatures_3}
          picAfter
          backgroundColor="grey"
        />
      )}
      {feauturesSections.host_3 && (
        <SectionFeatures featuresSection={feauturesSections.host_3} />
      )}
      {screenshotSections.hostFeatures_4 && (
        <SectionScreenshot
          imageSrc="/img/screenshots/host_4.png"
          effectSrc="/img/screenshots/effect_1.svg"
          screenshot={screenshotSections.hostFeatures_4}
          backgroundColor="grey"
        />
      )}
      {feauturesSections.host_4 && (
        <SectionFeatures featuresSection={feauturesSections.host_4} />
      )}
    </Layout>
  );
};
export default PageHost;

export const query = graphql`
  query PageFeaturesHostQuery {
    page: datoCmsPageFeaturesHost {
      heading
      leadingText
      buttonLabel
      buttonLink
      headlinePortals
      ...SectionPortals_portalsPage
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    testimonial: datoCmsTestimonial(identifier: { eq: "host_1" }) {
      ...SectionTestimonial_testimonial
    }

    allDatoCmsSectionScreenshot(
      filter: { identifier: { regex: "/^hostFeatures_/" } }
    ) {
      edges {
        node {
          identifier
          ...SectionScreenshot_screenshot
        }
      }
    }

    allDatoCmsFeatureSection(filter: { identifier: { regex: "/^host_/" } }) {
      edges {
        node {
          identifier
          ...FeatureSection_featureSectionConnection
        }
      }
    }
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;
